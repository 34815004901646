export const environment = {
	API_URL: "https://staging-unos-sienna-api.azurewebsites.net",
	BASE_URL: "https://staging.sienna.shockoe.dev",
	CONTAINERS: {
		PHOTOS: "test-photos",
		MODELS: "test-3d-models",
		OBJECTS: "test-ar-objects",
	},
	description: "QA",
};
